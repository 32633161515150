import { useMemo } from 'react';
import { Marker as MapMarker } from 'react-map-gl';

import { percentColour, useTheme } from '@jl/assets';
import { Icon } from './icon';
import { MarkerButton } from './marker.styles';

export interface MarkerProps {
  id: string;
  label: string;
  account: {
    label: string;
    detail?: string;
    api: string;
  };
  level: {
    time: Date | string;
    value: number | null;
    percent: number;
    maximum: number;
  } | null;
  unit: string;
  warnings: number[];
  location?: {
    latitude: number;
    longitude: number;
    display: string;
  };
  onSelect?: (id: string) => void;
  togglePopup?: (id: string) => void;
}

export const Marker = ({
  id,
  level,
  warnings,
  location,
  togglePopup,
}: MarkerProps) => {
  const theme = useTheme();
  if (!location) return null;

  return useMemo(() => {
    const colour = percentColour(theme, level && level.percent, warnings);

    return (
      <MapMarker
        latitude={location.latitude}
        longitude={location.longitude}
        offset={[-15, -30]}
      >
        <MarkerButton
          type="button"
          onClick={() => togglePopup && togglePopup(id)}
        >
          <Icon
            name="marker"
            colour={colour}
            outline={theme.background}
            size={30}
          />
        </MarkerButton>
      </MapMarker>
    );
  }, [
    id,
    theme,
    level && level.percent,
    warnings,
    location.latitude,
    location.longitude,
  ]);
};

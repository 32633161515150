import { Reducer, useEffect, useReducer, useRef, useState } from 'react';
import ReactMapGL, { NavigationControl, ViewState } from 'react-map-gl';
import { fitBounds } from 'viewport-mercator-project';
import 'mapbox-gl/dist/mapbox-gl.css';

import { breakPoint, useTheme } from '@jl/assets';
import { mapboxSettings, isNotNullOrUndefined } from '@jl/utils';
import { Marker, MarkerProps } from './marker';
import { Popup } from './popup';
import { useResize } from './resize';
import { useField } from './use-form';
import { UserMarker } from './user-marker';
import { MapContainer } from './map.styles';

interface ViewSize {
  width: number;
  height: number;
}
type FullViewState = ViewState & ViewSize;
export interface MapProps {
  markers?: MarkerProps[];
  size?: ViewSize;
  fill?: boolean;
  useCurrentLocation?: boolean;
}

const getBounds = (
  markers: { location?: { latitude: number; longitude: number } }[],
  key: 'latitude' | 'longitude',
) => {
  const values = markers
    .map(({ location }) => location && location[key])
    .filter(isNotNullOrUndefined);
  return [Math.min(...values), Math.max(...values)];
};

export const Map = ({ markers, size, fill, useCurrentLocation }: MapProps) => {
  const theme = useTheme();
  const rowRef = useRef<HTMLDivElement>(null);
  const [bounds, setBounds] = useState<[[number, number], [number, number]]>();
  const [viewport, setViewport] = useReducer<
    Reducer<FullViewState, Partial<FullViewState>>
  >(
    (state, action) => ({
      ...state,
      ...action,
    }),
    {
      width: size ? size.width : 0,
      height: size ? size.height : 0,
      latitude: -23.116667,
      longitude: 132.133333,
      zoom: 4,
      bearing: 0,
      pitch: 0,
      padding: { top: 0, left: 0, bottom: 0, right: 0 },
    },
  );

  const [current, setCurrent] = useState<{
    latitude: number;
    longitude: number;
  }>();
  const { setValue, setError } = useField('useCurrentLocation');
  useEffect(() => {
    let watcher: number;
    if (useCurrentLocation) {
      watcher = navigator.geolocation.watchPosition(
        (position) => {
          setCurrent({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          setCurrent(undefined);
          setValue(false);
          setError('useCurrentLocation', error.message);
        },
      );
    } else {
      setCurrent(undefined);
    }
    return () => {
      if (watcher) navigator.geolocation.clearWatch(watcher);
    };
  }, [useCurrentLocation]);

  useResize(() => {
    if (fill && rowRef.current) {
      if (window.innerWidth >= breakPoint) {
        setViewport({ width: 0 });
        setViewport({
          width: rowRef.current.clientWidth,
          height: window.innerHeight - 100,
        });
        rowRef.current.style.height = `${window.innerHeight - 100}px`;
      } else {
        setViewport({
          width: window.innerWidth - 20,
          height: window.innerHeight - 100,
        });
        rowRef.current.style.height = `${window.innerHeight - 100}px`;
      }
    }
  });
  useEffect(() => {
    if (markers && markers.length) {
      const items = [...markers, { location: current }];
      const [minLat, maxLat] = getBounds(items, 'latitude');
      const [minLng, maxLng] = getBounds(items, 'longitude');
      setBounds([
        [minLng, minLat],
        [maxLng, maxLat],
      ]);
    } else if (current) {
      setBounds([
        [current.longitude, current.latitude],
        [current.longitude, current.latitude],
      ]);
    }
  }, [markers, !!current]);
  useEffect(() => {
    if (viewport.width > 0 && viewport.height > 0 && bounds) {
      try {
        const fitted = fitBounds({
          width: viewport.width,
          height: viewport.height,
          bounds,
          padding: Math.max(viewport.width * 0.1, 50),
        });
        fitted.zoom = Math.min(12, fitted.zoom);
        setViewport(fitted);
      } catch (e) {}
    }
  }, [viewport.width, viewport.height, bounds]);
  const [popup, togglePopup] = useReducer(
    (state: { [k: string]: boolean }, action: string) => ({
      ...state,
      [action]: !state[action],
    }),
    {},
  );

  return (
    <MapContainer ref={rowRef}>
      <ReactMapGL
        mapboxAccessToken={mapboxSettings.accessToken}
        mapStyle={theme.map}
        onMove={({ viewState }) => setViewport(viewState)}
        onRotate={({ viewState }) => setViewport(viewState)}
        onZoom={({ viewState }) => setViewport(viewState)}
        {...viewport}
      >
        {current && <UserMarker {...current} />}
        {(markers || []).map((props) => (
          // eslint-disable-next-line react/prop-types
          <Marker key={props.id} {...props} togglePopup={togglePopup} />
        ))}
        {(markers || [])
          .filter(({ id }) => popup[id])
          .map((props) => (
            // eslint-disable-next-line react/prop-types
            <Popup key={props.id} {...props} togglePopup={togglePopup} />
          ))}
        <div style={{ position: 'absolute', right: 10, top: 10 }}>
          <NavigationControl />
        </div>
      </ReactMapGL>
    </MapContainer>
  );
};

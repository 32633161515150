import { styled } from '@jl/assets';

export const MarkerButton = styled.button`
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;

  color: ${(props) => props.theme.success};
  text-decoration: underline;
  font-size: 1.4rem;
  line-height: 1.6;

  &:focus {
    outline: none;
  }
`;

import { useMemo } from 'react';
import { Popup as MapPopup } from 'react-map-gl';
import { ClassNames } from '@emotion/react';

import { Subtitle, Hint } from '@jl/assets';
import {
  buildAmountString,
  isNullOrUndefined,
  specialCharacters,
} from '@jl/utils';
import { MarkerButton } from './marker.styles';
import { MarkerProps } from './marker';

export const Popup = ({
  id,
  label,
  unit,
  level,
  onSelect,
  location,
  togglePopup,
}: MarkerProps) => {
  if (!location) return null;

  return useMemo(() => {
    return (
      <ClassNames>
        {({ css, theme }) => (
          <MapPopup
            latitude={location.latitude}
            longitude={location.longitude}
            anchor="top"
            closeOnClick={false}
            onClose={() => togglePopup && togglePopup(id)}
            className={css`
              .mapboxgl-popup-tip {
                border-bottom-color: ${theme.background};
              }
              .mapboxgl-popup-content {
                background-color: ${theme.background};
              }
            `}
          >
            <Hint style={{ marginTop: 5 }}>
              <strong>{label}</strong>
            </Hint>
            <Subtitle />
            <Hint>
              <strong>
                {isNullOrUndefined(level)
                  ? ` ${specialCharacters.enDash} `
                  : Math.round(level.percent)}
                %
              </strong>
              {' - '}
              {buildAmountString(level, unit)}
            </Hint>
            <MarkerButton
              type="button"
              onClick={() => onSelect && onSelect(id)}
              style={{ marginTop: 5 }}
            >
              More Detail
            </MarkerButton>
          </MapPopup>
        )}
      </ClassNames>
    );
  }, [
    id,
    label,
    unit,
    level && level.percent,
    location.latitude,
    location.longitude,
  ]);
};

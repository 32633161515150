import { useMemo } from 'react';
import { Marker as MapMarker } from 'react-map-gl';

import { useTheme } from '@jl/assets';
import { Icon } from './icon';

export interface UserMarkerProps {
  latitude: number;
  longitude: number;
}

export const UserMarker = ({ latitude, longitude }: UserMarkerProps) => {
  const theme = useTheme();

  return useMemo(() => {
    return (
      <MapMarker latitude={latitude} longitude={longitude}>
        <Icon
          name="marker"
          colour={theme.action}
          outline={theme.foreground}
          size={30}
        />
      </MapMarker>
    );
  }, [latitude, longitude]);
};
